import React from 'react';
import style from '../styles/main.module.scss';
import * as Contentful from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as RTT from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { GallerySection } from '../components/gallerySection';
import { TypeGallerySectionFields } from '../types/contentfultypes';

export const Page: React.FC<{body:Contentful.Entry<any>|undefined, header?:string}> = (props) =>  {

  const {body, header} = props;
    
    var renderOptions = {
        renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node:any, children:any) => {
            const assetType = node.data.target.sys.contentType.sys.id;
            switch (assetType) {
            case "gallerySection":{
                return <div className={style.images}><GallerySection section={node.data.target.fields as TypeGallerySectionFields} /></div>
            }
            default:
                return "Nothing to see here...";
            }
        },
        [BLOCKS.EMBEDDED_ASSET]: (node:any, children:any) => {
            const assetType = node.data.target.fields.file.contentType;
            switch (assetType) {
            case "video/mp4":
                return (
                <video width="100%" height="100%" controls>
                    <source src={node.data.target.fields.file.url} type="video/mp4" />
                </video>
                );
            case "image/jpg":
                case "image/jpeg":
                    case "image/png":
                    return (
                    <img
                        src={`https://${node.data.target.fields.file.url}`}
                        width="100%"
                        alt={node.data.target.fields.description}
                    />
                    );
            default:
                return "Nothing to see here...";
            }
        },
        },
    }


    return <div className={style.about}>
        {header && <h2>{header}</h2>}
        {body && body.fields.pageContent && documentToReactComponents(body.fields.pageContent as RTT.Document,renderOptions)}
    </div>
           
}

export default Page;
