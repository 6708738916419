import { MainNavigation } from './components/mainNavigation';
import style from './styles/main.module.scss';
import { documentToReactComponents,  } from "@contentful/rich-text-react-renderer";
import * as RTT from '@contentful/rich-text-types';
import CustomRoutes from './components/customRoutes';
import useContentful from './datasources/contentful';

function App() {
  const {masterPage, pages} = useContentful();
  return (<div className={style.mainContainer}>
      {pages && <MainNavigation pages={pages}/>}
      {pages && masterPage && <CustomRoutes pages={pages} masterPage={masterPage} /> }
      <footer>{ documentToReactComponents(masterPage?.fields.footer as RTT.Document,{}) }</footer>
    </div>);
}
export default App;