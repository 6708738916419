import React from 'react';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import style from '../styles/main.module.scss';
import * as Contentful from "contentful";
import { TypePageFields } from '../types/contentfultypes';

function CustomLink({ children, to, ...props }: LinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
  
    return (
      <div>
        <Link style={{ textDecoration: match ? "underline" : "none" }} to={to}  {...props}>
          {children}
        </Link>        
      </div>
    );
  }

  export const MainNavigation: React.FC<{pages:Contentful.EntryCollection<TypePageFields>|undefined }> = (props) =>  {

    const {pages} = props;
    
      return (
          <nav className={style.navcontainer} role="menu" >
              <Link className={style.name} to={'/'}><h1>Assi Molin</h1></Link>
              <ul>
                {pages && pages.items
                  .sort((a,b) => (a.fields.menuSortOrder || 0) - (b.fields.menuSortOrder || 0))
                  .map( i => <li role="menuitem" key={i.fields.slug || '/'} >
                      <CustomLink className='' to={i.fields.slug || '/'}>{i.fields.title?.toUpperCase()}</CustomLink>
                    </li>)}
              </ul>
          </nav>
      );
  }