import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Children, useEffect, useId, useState } from "react";
import { createPortal } from "react-dom";
import style from '../styles/main.module.scss';

var appRootEl = document.getElementById("_lbroot");

export const LightBox : React.FC<{children?:React.ReactNode}> = (props) => {
    var [src,setSrc] = useState("");
    var id = useId().replaceAll(":","-");
    
    useEffect(() => {
        if(!appRootEl){
            appRootEl = document.createElement('div');
            appRootEl.id = "_lbroot";
            console.log("appending root el");
            document.body.append(appRootEl);
            return () => {
                console.log("removing root el");
                appRootEl?.remove();
            }
        }else{
            console.log("appending root el");
            document.body.append(appRootEl);
        }
    },[]);


    useEffect(() => {
        var items = document.querySelectorAll(".lb_gallery_" + id + " img");
        items.forEach(i => {
            var image = i as HTMLImageElement;
            image.onclick = () => {
                console.log(image.src);
                setSrc(image.src);
            }
        });
    },[id]);
   
    var backdropRef = useRef<HTMLDivElement>(null);         
    var backDropContainer = <>    
        <div className={style.backdrop} ref={backdropRef} onKeyDown={(evt) => {console.log(evt.code);} } onClick={() => { setSrc(""); }} >
        <img src={src} alt="" />
        {<div className={style.selectedImage}  onClick={() => { setSrc(""); }}><div></div></div>}
        </div>
        <FontAwesomeIcon className={style.closeIcon} icon={faCircleXmark} onClick={() =>{  setSrc(""); }} />
    </>;


    const clonedChildren = Children.map(Children.toArray(props.children), (child, index) => {
        var _child = child as React.ReactElement;
        var classNames = [];
        if(_child.props.className)
            classNames.push(_child.props.className);
        classNames.push("lb_gallery_" + id);
        return React.cloneElement(_child, {
            className: classNames.join(" ")
        })
    });

    return <>
        {clonedChildren}
        {appRootEl && src && createPortal(backDropContainer, document.body)}
    </>
}