import { Route, Routes } from 'react-router-dom';
import * as Contentful from "contentful";
import { TypeMasterPageFields, TypePageFields } from '../types/contentfultypes';
import Page from '../pages/page';
import Preview from '../pages/preview';


const CustomRoutes: React.FC<{pages:Contentful.EntryCollection<TypePageFields>|undefined, masterPage:Contentful.Entry<TypeMasterPageFields>|undefined }> = ({pages, masterPage}) => {  
    return (
        <Routes>
            <Route key="preview" path="/preview/:entryType/:entryId" element={<Preview />}/>
            {pages && pages.items.map(p => <Route key={p.sys.id} path={p.fields.slug} element={<Page
                key={p.sys.id}
                title={masterPage?.fields.siteTitle + " - " + p.fields.title || ""}
                body={(pages.includes.Entry as Contentful.Entry<any>[]).find((i) => i.sys.id === p.fields.body?.sys.id )} />} /> )}
        </Routes>
    );
}

export default CustomRoutes;