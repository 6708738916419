import { useEffect, useState, createElement } from 'react';
import * as Contentful from "contentful";
import style from '../styles/main.module.scss';
import { TypeGallerySectionFields, TypeMasterPageFields, TypePageFields } from '../types/contentfultypes';
import { BLOCKS } from '@contentful/rich-text-types';
import * as RTRR  from "@contentful/rich-text-react-renderer";
import * as RTT from '@contentful/rich-text-types';
import { ContentfulClientApi, Entry, EntryCollection } from 'contentful';


interface IState{
    masterPage?:Contentful.Entry<TypeMasterPageFields>;
    pages?:Contentful.EntryCollection<TypePageFields>;
}

function getContentfulClient(){
    const accessToken = "vlAggJ0CauDYttKwkReOshQKRs86p4ptNtNYkyBr5z8";
    const space = "64n63adoxhji";
    return Contentful.createClient({
        accessToken: accessToken,
        space: space
    });    
}

function loadPagesFromContentful(client:Contentful.ContentfulClientApi) : Promise<EntryCollection<TypePageFields>>{
    return client.getEntries<TypePageFields>({
        content_type: "page",
        "include" : "10"
    });    
}

function loadMasterPageFromContentful(client:ContentfulClientApi) : Promise<Entry<TypeMasterPageFields>|undefined>{
    return client.getEntries<TypeMasterPageFields>({
        content_type: "masterPage"
    }).then(data => {
        return data.items.length === 1 ? data.items[0] : undefined;
    });
}

function loadFromContentful() : Promise<[Entry<TypeMasterPageFields>|undefined,EntryCollection<TypePageFields>]>{
    const client = getContentfulClient()
    return Promise.all([loadMasterPageFromContentful(client), loadPagesFromContentful(client)]);
}

const useContentful =  () =>  {
    const [state,setState] = useState<IState>({});
    useEffect(() => {
        loadFromContentful().then(data => {
            const [masterPageData, pageData] = data; 
            setState({masterPage:masterPageData, pages:pageData});
        });
    },[]);
    return state;
}

const defaultRenderOptions:RTRR.Options = {
    renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node:any, children:any) => {
        const assetType = node.data.target.sys.contentType.sys.id;
        switch (assetType) {
        case "gallerySection":{
            return createElement("div", {className: style.images},
                createElement("GallerySection", { section :node.data.target.fields as TypeGallerySectionFields}) );
        }
        default:
            return "Nothing to see here...";
        }
    },

    [BLOCKS.EMBEDDED_ASSET]: (node:any, children:any) => {
        const assetType = node.data.target.fields.file.contentType;
        switch (assetType) {
        case "video/mp4":
            return createElement("video", {width:"100%", height:"100%"},
                createElement("source", { "src":node.data.target.fields.file.url, type:"videop/mp4"}) );
        case "image/jpg":
            case "image/jpeg":
                case "image/png":
                return createElement("img", { "src":`https://${node.data.target.fields.file.url}`, width:"100%", alt:node.data.target.fields.description})
        default:
            return "Nothing to see here...";
        }
    },
    },
}

export const documentToReactComponents = (richTextDocument: RTT.Document, renderOptions?: RTRR.Options) => { 
    return RTRR.documentToReactComponents(richTextDocument, renderOptions || defaultRenderOptions); 

}

export {loadPagesFromContentful, loadMasterPageFromContentful, getContentfulClient};
export default useContentful;