import React from 'react';
import * as Contentful from "contentful";
import { Helmet } from 'react-helmet-async';
import GeneralPageContent from './generalPageContent';


export const Page: React.FC<{title:string, body:Contentful.Entry<any>|undefined, header?:string}> = (props) =>  {

  const {title, body, header} = props;
    var content:React.ReactNode;
    
    if(!body)
      return <Helmet> <title>{title}</title> </Helmet>

    switch(body.sys.contentType.sys.id){
      case "generalPage":
        content = <GeneralPageContent body={body} header={header} key={body.sys.id}></GeneralPageContent>;
        break;
    }
    return <>
      <Helmet>
          <title>{title}</title>
      </Helmet>
      { content }
    </>
}

export default Page;
