import React from 'react';
import style from '../styles/main.module.scss';
import { TypeGallerySectionFields } from '../types/contentfultypes';
import { documentToReactComponents} from "@contentful/rich-text-react-renderer";
import * as RTT from '@contentful/rich-text-types';
import { LightBox } from './lightbox';


export const CustomImage : React.FC<{src:string}> = (props) => {
    return <>    
        <div><img src={props.src} alt="" /></div>
    </>;
}

export const GallerySection : React.FC<{section:TypeGallerySectionFields}> = (props) => {
    var classes = [style.serie];
    return  <LightBox><div className={classes.join(" ")} onClick={() => {}}>
        
            <div className={style.info}>
                <h3>{props.section.galleryTitle}</h3>
                {documentToReactComponents(props.section.galleryDescription as RTT.Document)}
            </div>
            {props.section.galleryImage && props.section.galleryImage.map(i => <CustomImage key={i.fields.file.url} src={i.fields.file.url} />)}
        
    </div></LightBox>
}