import React, { useEffect, useState } from 'react';
import style from '../styles/main.module.scss';
import * as Contentful from "contentful";
import * as RTT from '@contentful/rich-text-types';
import { documentToReactComponents } from '../datasources/contentful';
import { useParams } from 'react-router-dom';
import { TypeGallerySectionFields, TypeGeneralPageFields } from '../types/contentfultypes';
import { BLOCKS } from '@contentful/rich-text-types';
import { GallerySection } from '../components/gallerySection';
export const Preview: React.FC<{}> = () =>  {
  const props = useParams();
  const {entryType, entryId} = props;

  const [state, setState] = useState<TypeGeneralPageFields>();
  var content:React.ReactNode = "<></>";


  useEffect(() => {
    const client = Contentful.createClient({
      accessToken: "vlAggJ0CauDYttKwkReOshQKRs86p4ptNtNYkyBr5z8",
      space: "64n63adoxhji"
    })

    client.getEntry<TypeGeneralPageFields>( entryId || "a",{
        "include" : "10"
    }).then(data => {
      setState(data.fields);
    });
  },[entryId]);

  var renderOptions = {
    renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node:any, children:any) => {
        const assetType = node.data.target.sys.contentType.sys.id;
        switch (assetType) {
        case "gallerySection":{
            return <div className={style.images}><GallerySection section={node.data.target.fields as TypeGallerySectionFields} /></div>
        }
        default:
            return "Nothing to see here...";
        }
    },

    [BLOCKS.EMBEDDED_ASSET]: (node:any, children:any) => {
        const assetType = node.data.target.fields.file.contentType;
        switch (assetType) {
        case "video/mp4":
            return (
            <video width="100%" height="100%" controls>
                <source src={node.data.target.fields.file.url} type="video/mp4" />
            </video>
            );
        case "image/jpg":
            case "image/jpeg":
                case "image/png":
                return (
                <img
                    src={`https://${node.data.target.fields.file.url}`}
                    width="100%"
                    alt={node.data.target.fields.description}
                />
                );
        default:
            return "Nothing to see here...";
        }
    },
    },
}


  if(state) {
    switch(entryType){
      case "generalpage":
        content = <div className={style.about}>
          {documentToReactComponents(state.pageContent as RTT.Document, renderOptions)}</div>
        break;
      case "gallerypage":
        content = <div className={style.images}>Preview är ännu inte implementerat för denna typ av enhet</div>
        break;
      case "master":
        content = <div className={style.images}>Preview är ännu inte implementerat för denna typ av enhet</div>
        break;
      default:
        content = <div className={style.images}>Preview är ännu inte implementerat för denna typ av enhet</div>
        break;
    }
  }
    return <>
      { content }
    </>
}

export default Preview;
